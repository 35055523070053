<template>
  <div class="productImportation">
    <div class="section imageImport">
      <h1 class="md-title">{{ $t('productImportation.imageImportation') }}</h1>
      <div class="md-layout-item-vertical">
        <div class="center">
          <div class="centerContent">
            <file-upload ref="upload" v-model="files" @input-filter="filterFile" accept="application/x-zip-compressed"
              :drop="true">
              {{ $t('productImportation.selectZipFile') }} <i
                class="md-icon md-icon-font md-theme-default">library_add</i>
            </file-upload>
          </div>
          <div class="centerContent">
            <div v-for="(file) in files" :key="file.id">
              <span>{{ file.name }}</span><br />
              <span v-bind:class="{ fileTooLarge: !isFileSizeValid, fileSizeOk: isFileSizeValid }">{{
                  $t('productImportation.imageSizeToLarge')
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btnImport">
        <md-button class="md-primary" :disabled="!hasFiles || !isFileSizeValid || isImporting" @click="importImageFile">{{
            $t('productImportation.importImageFiles')
        }}</md-button>
      </div>
    </div>
    <div class="section descriptionImport">
      <div class="btnSection">
        <h1 class="md-title">{{ $t('productImportation.productInformationImportation') }}</h1>
      <a :href="importTemplate" download class="btn">{{ $t('productImportation.downloadTemplate') }}</a>
    </div>
      <div class="md-layout-item-vertical md-layout">
        <div class="md-layout-item-horizontal center">
          <div class="centerContent">
            <label class="md-button file-select md-theme-default">
              <span class="md-ripple">
                <span class="md-button-content">
                  {{ $t('productImportation.selectFile') }} <i
                    class="md-icon md-icon-font md-theme-default">library_add</i>
                  <input type="file" @change="importProductInformation" onclick="this.value=null;" />
                </span>
              </span>
            </label>
          </div>
          <div class="centerContent">
            <div v-if="fileName && hasProductInformationFile">
              <span>{{ fileName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <md-button class="md-primary" :disabled="!hasProductInformationFile || isImportingProductInformation" @click="importProductInformationFile">{{
            $t('productImportation.importProductInformation')
        }}</md-button>
      </div>
    </div>
    <div class="section descriptionImport">
      <div class="btnSection">
        <h1 class="md-title">{{ $t('productImportation.importPromotionsTitle') }}</h1>
        <md-button class="md-primary" @click="importPromotions">{{
              $t('productImportation.reimportPromotions')
          }}</md-button>
      </div>
      <div class="sectionTitle">
          <h4>{{ $t('productImportation.waitHint') }}</h4>
        </div>
    </div>
    <div class="section descriptionImport">
      <div class="btnSection">
        <h1 class="md-title">{{ $t('productImportation.synchronizeActivePromotionsTitle') }}</h1>
        <md-button class="md-primary" @click="synchronizeActivePromotions">{{
              $t('productImportation.synchronizeActivePromotions')
          }}</md-button>
      </div>
      <div class="sectionTitle">
        <h4>{{ $t('productImportation.synchronizeActivePromotionsHint') }}</h4>
      </div>
      <div class="sectionTitle">
        <h4>{{ $t('productImportation.waitHint') }}</h4>
      </div>
    </div>
    <div class="section descriptionImport">
      <div class="btnSection">
        <h1 class="md-title">{{ $t('productExportation.productExportationTitle') }}</h1>
        <md-button class="md-primary" :disabled="isExportingProductInformation" @click="exportProductsWithoutImage">{{
              $t('productExportation.exportProducts')
          }}</md-button>
      </div>
      <div class="sectionTitle">
          <h4>{{ $t('productExportation.waitHint') }}</h4>
        </div>
    </div>
    <div class="section descriptionImport">
      <div class="btnSection">
        <h1 class="md-title">{{ $t('productExportation.productWithImagesTitle') }}</h1>
        <md-button class="md-primary" :disabled="isExportingVisibleProductsWithImages" @click="exportProductsWithImages">{{
              $t('productExportation.exportProducts')
          }}</md-button>
      </div>
      <div class="sectionTitle">
          <h4>{{ $t('productExportation.waitHint') }}</h4>
        </div>
    </div>
  </div>
</template>

<script>
import authenticatedAxios from '../../authenticatedAxios';
import VueUploadComponent from 'vue-upload-component';

export default {
  data: () => ({
    isImporting: false,
    isImportingProductInformation: false,
    isExportingProductInformation: false,
    isExportingVisibleProductsWithImages: false,
    productInformationUpdatedCount: 0,
    importationHasError: false,
    errorMessages: [],
    fileName: '',
    summaryFileName: '',
    files: [],
    productInformationFile: null,
    importTemplate: '../assets/ProduitsDescriptionImportation.xls'
  }),
  components: {
    FileUpload: VueUploadComponent
  },
  methods: {
    importProductInformation(e) {
      let formData = new FormData();
      const file = e.target.files[0];
      formData.append('File', file);
      this.fileName = file.name;
      this.summaryFileName = file.name;
      this.productInformationFile = formData;
    },
    filterFile: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(zip)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    async importProductInformationFile() {
      this.$store.dispatch('product/ImportProductInformation', this.productInformationFile);

      if (this.isImportingProductInformation) {
        return;
      }

      this.isImportingProductInformation = true;
      try {
        let response = await authenticatedAxios.post(
          '/api/product/ImportProductInformation',
          this.productInformationFile,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

        var blob = new Blob([response.data], { type: 'text/plain' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.download = 'importation_information-produits-erreurs.csv';
        document.body.appendChild(a);
        a.href = url;
        a.click();
        document.body.removeChild(a);
        this.isImportingProductInformation = false;
      } catch (e) {
        this.isImportingProductInformation = false;
      }
    },
    async importImageFile() {
      if (this.isImporting) {
        return;
      }

      this.isImporting = true;
      try {
        let formData = new FormData();
        formData.append('File', this.files[0].file);
        
        let response = await authenticatedAxios.post(
          '/api/product/ImportImageFile',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

        var blob = new Blob([response.data], { type: 'text/plain' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.download = 'importation_image-produits-sommaire.csv';
        document.body.appendChild(a);
        a.href = url;
        a.click();
        document.body.removeChild(a);
        this.isImporting = false;
      } catch (e) {
        this.isImporting = false;
      }
    },
    importPromotions() {
      this.$store.dispatch('product/ImportPromotions');
    },
    synchronizeActivePromotions() {
      this.$store.dispatch('product/SynchronizeActivePromotions');
    },
    async exportProductsWithImages() {
      if (this.isExportingVisibleProductsWithImages) {
        return;
      }

      this.isExportingVisibleProductsWithImages = true;
      try {
        let response = await authenticatedAxios.get('/api/product/ExportVisibleProductsWithImages');

        var blob = new Blob([response.data], { type: 'text/plain' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.download = 'Visible-Products-With-Images-Exportation-Report.csv';
        document.body.appendChild(a);
        a.href = url;
        a.click();
        document.body.removeChild(a);
        this.isExportingVisibleProductsWithImages = false;
      } catch (e) {
        this.isExportingVisibleProductsWithImages = false;
      }
    },
    async exportProductsWithoutImage() {
      if (this.isExportingProductInformation) {
        return;
      }

      this.isExportingProductInformation = true;
      try {
        let response = await authenticatedAxios.get('/api/product/ExportVisibleProductsWithoutImage');

        var blob = new Blob([response.data], { type: 'text/plain' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.download = 'Visible-Products-Without-Image-Exportation-Report.csv';
        document.body.appendChild(a);
        a.href = url;
        a.click();
        document.body.removeChild(a);
        this.isExportingProductInformation = false;
      } catch (e) {
        this.isExportingProductInformation = false;
      }
    }
  },
  computed: {
    hasProductInformationFile() {
      return !!this.productInformationFile;
    },
    hasFiles() {
      return this.files.length > 0;
    },
    isFileSizeValid() {
      return this.files[0].file.size < 104857600;
    }
  }
};
</script>

<style lang="scss">
.productImportation .section {
  border-bottom: 1px solid #dadada;
  margin-bottom: 1.50rem;
  padding: 1.50rem 3.00rem;
}

.productImportation .md-title {
  font-size: 1.75rem;
  color: #2a2a2a;
  font-weight: 700;
  margin: 0 0 1.00rem 0;
}

.productImportation .center {
  margin: 0;
  width: 75%;
  padding: 1.50rem;
  margin-bottom: 1.50rem;
  border: 1px dashed #dadada;
  background: #f4f4f4;
  text-align: center;
}

.productImportation .sectionTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width:100%;
  padding-bottom:1.063rem;
}

.productImportation .md-button.md-theme-default {
  color: #2a2a2a;
}

.productImportation .md-icon.md-theme-default.md-icon-font {
  color: #2a2a2a;
}

.productImportation .btnImport {
  border-bottom: 0;
}

.productImportation button {
  width: 100%;
  font-weight: bold;
  color: #fff;
  background: #e20613;
  padding: 1rem 1.5rem;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  transition: .25s;
  height: auto;
  max-width: 225px;
  box-shadow: none;
  margin: 0;
}

.productImportation button .md-button-content {
  color: #fff;
}

.productImportation button:hover {
  background: #86040b;
}

.productImportation button:disabled {
  background: #E6E6E6;
  color: #BCBCBC;
}

.productImportation button:disabled .md-button-content {
  color: #BCBCBC;
}

.productImportation .messageSection {
  border-top: 1px solid #dadada;
  margin-top: 1.50rem;
  padding-top: 1.50rem;
  color: #2a2a2a;
}

.productImportation .messageSection .md-subheading {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 1.00rem;
}

.productImportation .messageSection .bold {
  font-weight: 700;
}

.productImportation .messageSection .bold span {
  font-weight: 400;
}

.productImportation .imageImport .centerContent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.fileSizeOk {
  display: none;
}

.fileSizeTooLarge {
  color: #ff1744;
  display: inline;
}

.productImportation .imageImport .file-uploads {
  min-width: 88px;
  height: 36px;
  margin: 6px 8px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 8px;
  transition: .4s cubic-bezier(.4, 0);
  cursor: pointer;
}

.productImportation .imageImport .file-uploads label {
  cursor: pointer;
}

.productImportation .imageImport .file-uploads:hover {
  background-color: #dcdcdc;
}

.productImportation .imageImport .md-icon {
  margin-left: 0.50rem;
}

.file-select .md-button-content>input[type="file"] {
  display: none;
}

.productImportation .btnSection {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 1.50rem;

  h1 { margin: 0;}
}

.productImportation a.btn {
  width: 100%;
  font-weight: bold;
  color: #fff;
  background: #e20613;
  padding: 1rem 1.5rem;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  transition: .25s;
  height: auto;
  max-width: 225px;
  box-shadow: none;
  margin: 0;
  text-align: center;
}

.productImportation a.btn:hover {
  background: #86040b;
  color: #fff;
}
</style>
